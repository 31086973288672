import { Customer } from "../cart/cart.state";

export interface UserState {
  user: Customer | null;
  updatePasswordLoading: boolean;
  updateAddressLoading: boolean;
}

export const initialState: UserState = {
  user: null,
  updatePasswordLoading: false,
  updateAddressLoading: false,
};
