import {
  HttpErrorResponse,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, filter, tap } from 'rxjs';
import { UserActions } from '../../state/user/user.actions';
import { Customer } from '../../state/cart/cart.state';
import { Router } from '@angular/router';
import { NavigationRoutes } from '../../shared/navigation-routes.enum';

export const userContextInterceptor: HttpInterceptorFn = (req, next) => {
  if (req.url.endsWith('/auth') && req.method === 'GET') {
    const store = inject(Store);
    const router = inject(Router);

    return next(req).pipe(
      filter((event) => event instanceof HttpResponse),
      tap((response) => {
        const httpResponse = response as HttpResponse<any>;

        // Set user context if response is OK and user is logged in
        if (httpResponse.ok) {
          const body = httpResponse.body as Customer;
          if (body) {
            store.dispatch(UserActions.setUser({ user: body }));
          }
        }

        // Remove user context if response is 401 (unauthorized)
        if (httpResponse.status === 401) {
          store.dispatch(UserActions.logout());
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          store.dispatch(UserActions.logout());
          router.navigate([NavigationRoutes.Login]);
        }
        return EMPTY;
      }),
    );
  }

  return next(req);
};
