import { CanMatchFn, Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, firstValueFrom, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { selectUser } from '../../state/user/user.selectors';
import { CartActions } from '../../state/cart/cart.actions';
import { Customer } from '../../state/cart/cart.state';

export const authGuard: CanMatchFn = async () => {
  const store = inject(Store);
  const router = inject(Router);
  const httpClient = inject(HttpClient);

  const customer =
    (await firstValueFrom(store.select(selectUser))) ??
    (await performAuthCheck(httpClient));

  if (customer) {
    store.dispatch(CartActions.createCartIfNotExists());

    return true;
  }

  router.navigate(['/login']);
  return false;
};

export const performAuthCheck = (httpClient: HttpClient) => {
  const customerUrl = `${environment.baseApiUrl}/auth`;

  return firstValueFrom(
    httpClient
      .get<Customer>(customerUrl, { withCredentials: true })
      .pipe(catchError(() => of(null))),
  );
};
