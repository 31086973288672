import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { selectUser } from '../../state/user/user.selectors';
import { performAuthCheck } from '../auth/auth.guard';

export const manageUsersGuard: CanMatchFn = async () => {
  const store = inject(Store);
  const router = inject(Router);
  const httpClient = inject(HttpClient);

  const customer =
    (await firstValueFrom(store.select(selectUser))) ??
    (await performAuthCheck(httpClient));

  if (
    customer &&
    customer?.groups?.findIndex((group) => group.name === 'Manage.Customers') >=
      0
  ) {
    return true;
  }

  router.navigate(['/']);
  return false;
};
