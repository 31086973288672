import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NavbarComponent } from './modules/navbar/navbar.component';
import { MediaQueryService } from './shared/media-query.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import en from '../assets/i18n/en.json';
import de from '../assets/i18n/de.json';
import { ImgComponent } from './modules/components/img/img.component';
import { MatIcon } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'sf-root',
  standalone: true,
  imports: [
    CommonModule,
    NavbarComponent,
    RouterOutlet,
    ImgComponent,
    MatIcon,
    MatButtonModule,
    TranslocoModule,
  ],
  templateUrl: './app.component.html',
})
export class AppComponent {
  public mediaQueryService = inject(MediaQueryService);
  translocoService = inject(TranslocoService);

  router = inject(Router);

  scrollY = 0;

  ngOnInit() {
    window.addEventListener('scroll', this.onWindowScroll);
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onWindowScroll);
  }

  constructor() {
    this.translocoService.setTranslation(en, 'en');
    this.translocoService.setTranslation(de, 'de');

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.scrollToTop();
      }
    });
  }

  onWindowScroll = () => {
    this.scrollY = window.scrollY || document.documentElement.scrollTop;
  };

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
