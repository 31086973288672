import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, finalize, map, tap } from 'rxjs/operators';
import { ApiService } from '../../shared/api.service';
import { NavigationRoutes } from '../../shared/navigation-routes.enum';
import { UserActions } from './user.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Customer, Order } from '../cart/cart.state';
import { SnackbarService } from '../../modules/snackbar/snackbar.service';

@Injectable()
export class UserEffects {
  private apiService = inject(ApiService);
  private httpClient = inject(HttpClient);

  private actions$ = inject(Actions);
  private router = inject(Router);
  private snackbarService = inject(SnackbarService);

  getUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.getUser),
      exhaustMap(() =>
        this.httpClient
          .get<{ customer: Customer }>(`${environment.baseApiUrl}/auth`, {
            withCredentials: true,
          })
          .pipe(
            map((user) => UserActions.setUser({ user: user.customer })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.logout),
      exhaustMap(() =>
        this.apiService.logout().pipe(
          map(() => {
            return UserActions.removeUser();
          }),
          catchError((err) => {
            return of(UserActions.removeUser());
          }),
          finalize(() => {
            this.router.navigateByUrl(NavigationRoutes.Login);
          }),
        ),
      ),
    ),
  );

  updateBillingAddress$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updateBillingAddress),
      exhaustMap((billing_address) =>
        this.httpClient
          .post<{ customer: Customer }>(
            `${environment.baseApiUrl}/customers/me`,
            {
              billing_address: {
                first_name: billing_address.first_name,
                last_name: billing_address.last_name,
                address_1: billing_address.address_1,
                address_2: billing_address.address_2,
                postal_code: billing_address.postal_code,
                city: billing_address.city,
                phone: billing_address.phone,
              },
            },
            {
              withCredentials: true,
            },
          )
          .pipe(
            tap(() =>
              this.snackbarService.openSnackbar('Adresse aktualisiert'),
            ),
            map(
              (user) => UserActions.setUser({ user: user.customer }),
              catchError(() => EMPTY),
            ),
          ),
      ),
    ),
  );

  updatePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.updatePassword),
      exhaustMap(({ password }) =>
        this.httpClient
          .post<{ customer: Customer }>(
            `${environment.baseApiUrl}/customers/me`,
            { password },
            {
              withCredentials: true,
            },
          )
          .pipe(
            tap(() =>
              this.snackbarService.openSnackbar('Passwort aktualisiert'),
            ),
            map(
              (user) => UserActions.setUser({ user: user.customer }),
              catchError(() => EMPTY),
            ),
          ),
      ),
    ),
  );

  order$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.loadOrders),
      exhaustMap(() =>
        this.httpClient
          .get<{ orders: Order[] }>(
            `${environment.baseApiUrl}/customers/me/orders`,
            {
              withCredentials: true,
            },
          )
          .pipe(
            map(
              (orders) => UserActions.setOrders({ orders: orders.orders }),
              catchError(() => EMPTY),
            ),
          ),
      ),
    ),
  );
}
