import { createReducer, on } from '@ngrx/store';
import { initialState } from './cart.state';
import { CartActions } from './cart.actions';

export const CartReducer = createReducer(
  initialState,
  on(CartActions.setCart, (state, { cart }) => ({
    ...state,
    cart,
    loading: false,
  })),
  on(CartActions.attemptCheckout, (state, {}) => ({
    ...state,
    loading: true,
  })),
  on(CartActions.setLoading, (state, { loading }) => ({
    ...state,
    loading,
  })),
);
