{
  "navigation": {
    "home": "Home",
    "catalog": "Katalog",
    "offers": "Angebote",
    "guudie": "Guudie",
    "cart": "Warenkorb",
    "checkout": "Auschecken",
    "profile": "Profil",
    "manage": "Unternehmen",
    "login": "Login",
    "logout": "Ausloggen",
    "password-reset": "Passwort Zurücksetzen"
  },
  "catalog": {
    "showAll": "Alle anzeigen",
    "goToOffer": "zum Angebot",
    "addToCart": "In den Warenkorb"
  },
  "login": {
    "signIn": "Einloggen",
    "resetPassword": "Passwort zurücksetzen",
    "successReset": "Wenn Ihre E-Mail registriert ist, erhalten Sie in Kürze eine E-Mail zum Zurücksetzen.",
    "email": "Email-Adresse",
    "login": "Login",
    "password": "Passwort",
    "submit": "Abschicken",
    "forgotPassword": "Passwort vergessen?",
    "backToLogin": "Zurück zum Login",
    "error": {
      "login": "Es ist ein Fehler aufgetreten. Bitte überprüfen Sie Ihre E-Mail und Ihr Passwort!",
      "serviceUnavailable": "Aufgrund von Wartungsarbeiten ist unsere Plattform momentan nicht erreichbar. Bitte versuche es später erneut."
    }
  },
  "input": {
    "required": "Dieses Feld ist erforderlich",
    "email": "Bitte geben Sie eine gültige E-Mail Adresse ein",
    "pattern": "Bitte geben Sie einen gültigen Wert ein",
    "invalidEmail": "Bitte geben Sie eine gültige E-Mail Adresse ein",
    "hidePassword": "Hide password",
    "passwordMissmatch": "Passwords do not match",
    "min": "Mindestanzahl ist {{min}}",
    "max": "Maximum ist {{max}}"
  },
  "insteadOf": "statt",
  "toProduct": "Zum Produkt",
  "addToCart": "Zum Warenkorb hinzufügen",
  "category": "Kategorie",
  "discount": "Rabatt",
  "goToDiscountCode": "Zum Gutschein gehen",
  "discountCodeCopied": "Gutscheincode kopiert",
  "quantity": "Anzahl",
  "showCouponCode": "Gutscheincode anzeigen",
  "offers": {
    "soldOut": "Ausverkauft",
    "clearFilters": "Alle Filter zurücksetzen ({{count}})",
    "noProducts": "Keine Produkte gefunden",
    "productType": {
      "purchase-coupon": "Einkaufsgutschein",
      "purchase-product": "Produkte",
      "coupon": "Rabattcode",
      "link": "Rabattlink"
    }
  },
  "guudieDetail": {
    "soldOut": "Ausverkauft",
    "openLink": "Link in neuer Registerkarte öffnen",
    "description": "Beschreibung",
    "similarProducts": "Ähnliche Produkte",
    "showMore": "Mehr anzeigen",
    "showLess": "Weniger anzeigen",
    "variant": "Variante",
    "redeemAt": "Einlösen auf:"
  },
  "checkout": {
    "noAddress": "Keine Rechnungsadresse hinterlegt. Bitte füge in Deinem Profil eine Rechnungsadresse hinzu.",
    "toProfile": "Zum Profil",
    "continueShopping": "Weiter einkaufen",
    "mobilityBudget": "Verfügbares Mobilitätsbudget:",
    "mobilityBudgetAmount": "Für diese Bestellung will ich folgenden Betrag von meinem Mobilitätsbudget verwenden:",
    "mobilityBudgetRemoved": "Das Mobilitätsbudget wurde entfernt, da sich die Menge geändert hat. Bitte füge das Mobilitätsbudget erneut hinzu.",
    "subtract": "Abziehen",
    "total": "Gesamtkosten (inkl. MwSt.):",
    "toCheckout": "Zur Kasse",
    "backToShop": "Zurück zum Shop",
    "cartEmpty": "Warenkorb ist leer",
    "yourCart": "Dein Warenkorb",
    "product": "Produkt",
    "minusMobilityBudget": "abzüglich Mobilitätsbudget:",
    "shippingAddress": "Lieferadresse",
    "useBillingAddress": "Mit Rechnungsadresse identisch",
    "useDifferenntShippingAddress": "Eine andere Lieferadresse angeben",
    "useExistingShippingAddress": "Bestehende Lieferadresse verwenden",
    "newShippingAddress": "Neue Lieferadresse",
    "failed": {
      "somethingWentWrong": "Etwas ist schief gegangen.",
      "tryAgain": "Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.",
      "backToCart": "Zurück zum Warenkorb"
    },
    "success": {
      "thankYou": "Vielen Dank für Deine Bestellung!",
      "receiveEmail": "Du erhältst in Kürze eine Email von uns.",
      "toProducts": "Zurück zu den Produkten"
    }
  },
  "setPassword": {
    "email": "Email-Adresse",
    "resetPassword": "Passwort setzen",
    "confirmPassword": "Passwort wiederholen",
    "password": "Passwort",
    "setPassword": "Passwort setzen",
    "success": "Password erfolgreich gesetzt.",
    "tokenInvalid": "Der Link scheint abgelaufen zu sein. Beantrage einen neuen unter <a class='hover:underline text-blue-600' href=\"/login\">Passwort vergessen</a> oder melde dich beim Support unter <a class='hover:underline text-blue-600' href=\"mailto:support@guudies.ch\">support@guudies.ch</a>",
    "error": "Es ist ein Fehler aufgetreten. Kontaktieren Sie den Support."
  },
  "manage": {
    "company": {
      "details": "Unternehmensangaben",
      "name": "Name des Unternehmens",
      "save": "Speichern"
    },
    "dashboard": {
      "dashboard": "Übersicht",
      "employees": "Mitarbeitende",
      "hrAdmin": "HR-Admin",
      "nrMobilityBudgetOrders": "Anzahl Mobilitätsbudget-Bestellungen",
      "totalValueMobilityBudgetOrders": "Totalwert der Mobilitätsbudget-Bestellungen"
    },
    "order": {
      "show": {
        "details": "Mobilitätsbudget Bestellung Detail"
      },
      "orderMobilityBudget": "Mobilitätsbudget bestellen",
      "allocateMobilityBudget": "Mobilitätsbudget vergeben",
      "mobilityBudgetOrderSuccess": "Mobilitätsbudget erfolgreich bestellt",
      "mobilityBudgetOrderFailure": "Mobilitätsbudget Bestellung fehlgeschlagen",
      "orderForNrUsers": "Bestellung für Anzahl Benutzer",
      "total": "Bestellung Total"
    },
    "users": {
      "create": {
        "withCsvImport": "Per CSV Import:",
        "invite": "Benutzer Einladen",
        "fileUpload": "Lade eine CSV Datei hoch",
        "or": "oder",
        "fileDragDrop": "CSV ziehen und ablegen",
        "csvWrong1": "Die CSV Datei muss der",
        "csvWrong2": "Vorlage",
        "csvWrong3": "entsprechen!",
        "downloadTemplate": "Vorlage Herunterladen",
        "invitedEmployees": "{{count}} Mitarbeitende(n) einladen",
        "duplicateError": "Upload fehlgeschlagen. Eine oder mehrere E-Mail-Adressen sind bereits registriert."
      },
      "delete": {
        "deleteUser": "Benutzer Löschen",
        "confirmDelete": "Soll Benutzer {{ firstName }} {{ lastName }} ({{ email }}) wirklich gelöscht werden?",
        "confirmInfo": "Dieser Vorgang kann nicht rückgängig gemacht werden."
      },
      "edit": {
        "editUser": "Benutzer Editieren"
      },
      "manageEmployees": "Mitarbeitende verwalten"
    },
    "backToShop": "Zurück zum Shop"
  },
  "profile": {
    "orders": "Bestellungen",
    "orderDetails": "Bestellung",
    "orderFrom": "vom",
    "backToShop": "Zurück zum Shop",
    "personalData": "Persönliche Daten",
    "mobilityBudget": "Mobilitätsbudget",
    "myMobilityBudget": "Mein Mobilitätsbudget",
    "availableMobilityBudget": "Dein aktuell verfügbares Mobilitätsbudget:",
    "saveAddress": "Adresse speichern",
    "newPassword": "Neues Passwort",
    "repeatNewPassword": "Neues Passwort wiederholen",
    "changePassword": "Passwort ändern",
    "order": {
      "number": "Bestellnummer",
      "date": "Bestelldatum",
      "total": "Total Bestellwert",
      "productName": "Bezeichnung",
      "variant": "Variante",
      "usedMobilityBudget": "Verwendetes Mobilitätsbudget",
      "deliveryAddress": "Lieferadresse",
      "billingAddress": "Rechnungsadresse"
    }
  },
  "footer": {
    "dataPrivacy": "Datenschutzerklärung",
    "generalTerms": "AGB",
    "impressum": "Impressum"
  },
  "info": {
    "street": "Strasse",
    "street2": "Addresszusatz",
    "poBox": "Postfach",
    "city": "Stadt",
    "zipCode": "PLZ",
    "country": "Land",
    "costCenter": "Kostenstelle",
    "vatNumber": "MwSt.-Nummer",
    "personResponsible": "Kontaktperson Vor- und Nachname",
    "personResponsibleEmail": "Kontaktperson E-Mailadresse",
    "nameChange": "Namensänderungen müssen beim Support beantragt werden.",
    "firstName": "Vorname",
    "lastName": "Nachname",
    "company": "Unternehmen",
    "billingAddress": "Rechnungsadresse",
    "streetAndNumber": "Strasse und Hausnummer",
    "additionalAddressInfo": "Zusatz",
    "total": "Total",
    "email": "E-mail",
    "mobilityBudget": "Mobilitätsbudget",
    "amount": "Betrag",
    "actions": "Aktionen",
    "phone": "Telefon",
    "password": "Passwort"
  },
  "action": {
    "edit": "Bearbeiten",
    "delete": "Löschen",
    "add": "Hinzufügen",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "back": "Zurück",
    "confirm": "Bestätigen",
    "close": "Schliessen",
    "order": "Bestellen",
    "login": "Einloggen"
  },
  "audit": {
    "createdBy": "Erstellt von",
    "createdOn": "Erstellt am",
    "updatedBy": "Aktualisiert von",
    "updatedOn": "Aktualisiert am"
  }
}
