import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import Hotjar from '@hotjar/browser';
import { environment } from './environments/environment';

if (environment.hotjarSiteId) {
  const hotjarVersion = 6;
  Hotjar.init(environment.hotjarSiteId, hotjarVersion);
}

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
