import { createReducer, on } from '@ngrx/store';
import { UserActions } from './user.actions';
import { initialState } from './user.state';
import { Customer } from '../cart/cart.state';

export const UserReducer = createReducer(
  initialState,
  on(UserActions.setUser, (state, { user }) => ({
    ...state,
    user: {
      ...state.user,
      ...user,
    },
    updatePasswordLoading: false,
    updateAddressLoading: false,
  })),
  on(UserActions.setOrders, (state, { orders }) => ({
    ...state,
    user: {
      ...state.user,
      orders
    } as Customer,
    updatePasswordLoading: false,
    updateAddressLoading: false,
  })),
  on(UserActions.updatePassword, (state) => ({
    ...state,
    updatePasswordLoading: true,
  })),
  on(UserActions.updateBillingAddress, (state) => ({
    ...state,
    updateAddressLoading: true,
  })),
  on(UserActions.removeUser, (state) => ({ ...state, user: null })),
  on(UserActions.logout, (state) => ({ ...state, user: null })),
);
