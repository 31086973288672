import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { EMPTY, catchError, throwError } from 'rxjs';
import { SnackbarService } from '../../modules/snackbar/snackbar.service';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { UserActions } from '../../state/user/user.actions';
import { Router } from '@angular/router';
import { NavigationRoutes } from '../../shared/navigation-routes.enum';

export const maintenanceModeInterceptor: HttpInterceptorFn = (req, next) => {
  const snackbarService = inject(SnackbarService);
  const transloco = inject(TranslocoService);
  const store = inject(Store);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error?.status === 503) {
        store.dispatch(UserActions.removeUser());
        router.navigate([NavigationRoutes.Login]);
        snackbarService.warnSnackbar(
          transloco.translate('login.error.serviceUnavailable'),
        );
      }
      throw error;
    }),
  );
};
