import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ManageActions } from './manage.actions';
import { EMPTY, catchError, exhaustMap, map } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SnackbarService } from '../../modules/snackbar/snackbar.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class ManageEffects {
  private httpClient = inject(HttpClient);
  private actions$ = inject(Actions);
  private transloco = inject(TranslocoService);
  private snackbarService = inject(SnackbarService);

  loadDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.loadDashboard),
      exhaustMap(() =>
        this.httpClient
          .get<any>(`${environment.baseApiUrl}/manage/dashboard`, {
            withCredentials: true,
          })
          .pipe(
            map((dashboard) => ManageActions.setDashboard({ dashboard })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  loadCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.loadCompany),
      exhaustMap(() =>
        this.httpClient
          .get<any>(`${environment.baseApiUrl}/manage/company`, {
            withCredentials: true,
          })
          .pipe(
            map((company) => ManageActions.setCompany({ company })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.updateCompany),
      exhaustMap((companyUpdate) =>
        this.httpClient
          .post(`${environment.baseApiUrl}/manage/company`, companyUpdate, {
            withCredentials: true,
          })
          .pipe(
            map((company) => ManageActions.setCompany({ company })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  loadCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.loadCustomers),
      exhaustMap(() =>
        this.httpClient
          .get<any>(`${environment.baseApiUrl}/manage/customers`, {
            withCredentials: true,
          })
          .pipe(
            map((customers) => ManageActions.setCustomers({ customers })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  createCustomers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.createCustomers),
      exhaustMap(({ customers }) =>
        this.httpClient
          .post(
            `${environment.baseApiUrl}/manage/customers`,
            {
              customers,
            },
            { withCredentials: true },
          )
          .pipe(
            map(() => ManageActions.loadCustomers()),
            catchError((err) => {
              if (err instanceof HttpErrorResponse) {
                if (err.error?.type === 'duplicate_error') {
                  console.error(err.error?.message);
                  this.snackbarService.warnSnackbar(
                    this.transloco.translate(
                      'manage.users.create.duplicateError',
                    ),
                  );
                }
              }
              return EMPTY;
            }),
          ),
      ),
    ),
  );

  loadMobilityOrders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.loadMobilityOrders),
      exhaustMap(() =>
        this.httpClient
          .get<any>(`${environment.baseApiUrl}/manage/mobility-orders`, {
            withCredentials: true,
          })
          .pipe(
            map((mobilityOrders) =>
              ManageActions.setMobilityOrders({ mobilityOrders }),
            ),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  createMobilityOrder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.createMobilityOrder),
      exhaustMap(({ orders }) =>
        this.httpClient
          .post(
            `${environment.baseApiUrl}/manage/mobility-orders`,
            {
              orders,
            },
            { withCredentials: true },
          )
          .pipe(
            map(() => {
              this.snackbarService.openSnackbar(
                this.transloco.translate(
                  'manage.order.mobilityBudgetOrderSuccess',
                ),
              );
              return ManageActions.loadMobilityOrders();
            }),
            catchError(() => {
              this.snackbarService.openSnackbar(
                this.transloco.translate(
                  'manage.order.mobilityBudgetOrderFailure',
                ),
              );
              return EMPTY;
            }),
          ),
      ),
    ),
  );

  deleteCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.deleteCustomers),
      exhaustMap(({ customer_ids }) =>
        this.httpClient
          .delete(`${environment.baseApiUrl}/manage/customers`, {
            withCredentials: true,
            body: {
              customer_ids,
            },
          })
          .pipe(
            map(() => ManageActions.loadCustomers()),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  editCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ManageActions.editCustomers),
      exhaustMap(({ customers }) =>
        this.httpClient
          .put(
            `${environment.baseApiUrl}/manage/customers`,
            {
              customers,
            },
            { withCredentials: true },
          )
          .pipe(
            map(() => ManageActions.loadCustomers()),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );
}
