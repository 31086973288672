import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  Signal,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { MatIconModule } from '@angular/material/icon';
import {
  ActivatedRoute,
  Router,
  RouterModule,
  RoutesRecognized,
} from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { NavigationRoutes } from '../../shared/navigation-routes.enum';
import {
  selectMobilityBudget,
  selectUser,
} from '../../state/user/user.selectors';

import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSidenav, MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { selectCart } from '../../state/cart/cart.selector';
import { UserActions } from '../../state/user/user.actions';
import { ImgComponent } from '../components/img/img.component';

@Component({
  selector: 'sf-navbar',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,

    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatSidenavModule,
    MatListModule,
    MatDividerModule,
    MatMenuModule,
    MatBadgeModule,
    ImgComponent,
  ],
  templateUrl: './navbar.component.html',
  styles: [
    `
      .navbar {
        grid-template-columns: 1fr 1fr 1fr auto;
        justify-content: space-between;
        align-items: center;
        display: grid;
      }
    `,
  ],
})
export class NavbarComponent {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  @Input() isMobile = false;
  @Input() displayType: 'navbar' | 'list' = 'navbar';

  @Output() toggleSidenav = new EventEmitter<void>();

  private translocoService = inject(TranslocoService);
  private store = inject(Store);
  public router = inject(Router);
  public activatedRoute = inject(ActivatedRoute);
  private extendClassRoutesToCheck = [
    `/${NavigationRoutes.Profile}`,
    `/${NavigationRoutes.Checkout}`,
    `/${NavigationRoutes.Manage}`,
  ];
  navigationRoutes = NavigationRoutes;
  cart$ = this.store.select(selectCart);
  mobilityBudget$ = this.store.select(selectMobilityBudget);
  extendClass: string | undefined = undefined;

  user$ = this.store.select(selectUser);
  hasManageUsersAccess$ = this.user$.pipe(
    map((user) =>
      user
        ? user.groups?.findIndex(
            (group) => group.name === 'Manage.Customers',
          ) >= 0
        : false,
    ),
  );

  mobileShowMenu = signal(false);

  routes = signal(this.getRoutes());
  menuRoutes = computed(() =>
    this.routes().filter((x) => x.position === 'menu' && x.show()),
  );

  constructor(private injector: Injector) {
    this.router.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        this.extendClass = this.extendClassRoutesToCheck.some((route) =>
          data.url.startsWith(route),
        )
          ? 'extended'
          : undefined;
      }
    });
  }

  getRoutes(): {
    route: string;
    show: Signal<boolean>;
    name: string;
    icon?: { type: 'img' | 'icon' | 'svg'; name: string };
    position: 'start' | 'center' | 'end' | 'menu' | 'after-menu';
    badge?: Signal<number>;
    action?: () => void;
  }[] {
    return [
      {
        route: NavigationRoutes.Checkout,
        show: toSignal(this.user$.pipe(map((x) => !!x)), {
          injector: this.injector,
          initialValue: true,
        }),
        name: this.translocoService.translate(
          `navigation.${NavigationRoutes.Checkout}`,
        ),
        icon: { name: 'cart.svg', type: 'svg' },
        position: 'end',
        badge: toSignal(
          this.cart$.pipe(
            map((cart) =>
              (cart?.items ?? []).reduce((acc, curr) => acc + curr.quantity, 0),
            ),
          ),
          {
            injector: this.injector,
            initialValue: 0,
          },
        ),
      },
      {
        route: NavigationRoutes.Profile,
        show: toSignal(this.user$.pipe(map((x) => !!x)), {
          injector: this.injector,
          initialValue: false,
        }),
        name: this.translocoService.translate(
          `navigation.${NavigationRoutes.Profile}`,
        ),
        icon: { name: 'person', type: 'icon' },
        position: 'menu',
        badge: toSignal(
          this.mobilityBudget$.pipe(
            map((mobilityBudget) => (mobilityBudget ?? 0) / 100),
          ),
          {
            injector: this.injector,
            initialValue: 0,
          },
        ),
      },
      {
        route: NavigationRoutes.Manage,
        show: toSignal(this.hasManageUsersAccess$, {
          injector: this.injector,
          initialValue: false,
        }),
        icon: { name: 'unternehmensprofil.svg', type: 'svg' },
        name: this.translocoService.translate(
          `navigation.${NavigationRoutes.Manage}`,
        ),
        position: 'menu',
      },
      {
        route: NavigationRoutes.Logout,
        show: toSignal(this.user$.pipe(map((x) => !!x)), {
          injector: this.injector,
          initialValue: false,
        }),
        action: () => this.store.dispatch(UserActions.logout()),
        name: this.translocoService.translate(
          `navigation.${NavigationRoutes.Logout}`,
        ),
        position: 'menu',
      },
    ];
  }
}
