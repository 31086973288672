<div>
  <sf-navbar
    displayType="navbar"
    [isMobile]="mediaQueryService.isMobile()"
  ></sf-navbar>

  <div class="bg-guud-white-palette-200">
    <router-outlet></router-outlet>
  </div>

  <button
    *ngIf="scrollY > 250"
    class="!fixed top-[85%] right-12"
    mat-mini-fab
    color="primary"
    (click)="scrollToTop()"
  >
    <mat-icon>keyboard_arrow_up</mat-icon>
  </button>

  <footer class="mt-4 z-50 bg-guud-green-palette-650 flex w-full select-none">
    <div
      class="container mx-auto py-8 flex justify-between flex-wrap p-8 sm:p-0 sm:py-8"
    >
      <div class="flex flex-col gap-12">
        <sf-img
          class="w-28 text-guud-white"
          type="svg"
          src="guudies-logo.svg"
        ></sf-img>
        <div class="flex flex-col text-guud-white">
          <span>Guud Group GmbH</span>
          <span>Wiesentalstrasse 8A</span>
          <span>8962 Bergdietikon</span>
          <span class="hover:underline">
            <a href="mailto:info@guudies.ch">info&commat;guudies.ch</a>
          </span>
        </div>
        <sf-img
          class="w-64 text-guud-white"
          style="margin-left: -24px"
          type="svg"
          src="energieschweiz.svg"
        ></sf-img>
      </div>
      <div class="flex flex-col gap-12 justify-between">
        <div>
          <span class="text-guud-white"></span>
        </div>
        <div>
          <span class="text-guud-white"></span>
        </div>
        <div class="flex flex-wrap gap-8 flex-row text-guud-white">
          <span>© 2024 Guudies</span>
          <span class="hover:underline cursor-pointer">
            <a href="https://www.guudies.ch/policies/privacy-policy">{{
              "footer.dataPrivacy" | transloco
            }}</a>
          </span>
          <span class="hover:underline cursor-pointer">
            <a href="https://www.guudies.ch/policies/terms-of-service">{{
              "footer.generalTerms" | transloco
            }}</a>
          </span>
          <span class="hover:underline cursor-pointer">
            <a href="https://www.guudies.ch/policies/legal-notice">{{
              "footer.impressum" | transloco
            }}</a>
          </span>
        </div>
      </div>
    </div>
  </footer>
</div>
