import { createReducer, on } from '@ngrx/store';
import { initialState } from './manage.state';
import { ManageActions } from './manage.actions';

export const ManageReducer = createReducer(
  initialState,
  on(ManageActions.setDashboard, (state, { dashboard }) => ({
    ...state,
    dashboard,
  })),
  on(ManageActions.setCompany, (state, { company }) => ({
    ...state,
    company,
    updateCompanyLoading: false,
  })),
  on(ManageActions.updateCompany, (state) => ({
    ...state,
    updateCompanyLoading: true,
  })),
  on(ManageActions.setMobilityOrders, (state, { mobilityOrders }) => ({
    ...state,
    mobilityOrders,
  })),
  on(ManageActions.setCustomers, (state, { customers }) => ({
    ...state,
    customers,
  })),
);
