import { Customer } from '../cart/cart.state';

export interface ManageState {
  customers: any[];
  mobilityOrders: MobilityOrder[];
  dashboard: any;
  company: any;
  updateCompanyLoading: boolean;
}

export interface MobilityOrderDetail {
  customer_id: string;
  ordered_budget: number;
  mobility_order_id: string;
  customer: Customer;
  id: string;
}

export interface MobilityOrder {
  id: string;
  created_by_customer_id: 'cus_01HRWV10ZEY5HVD6TWBYBDRV6K';
  created_by_customer?: Customer;
  company_id: string;
  order_total: number;
  orders: MobilityOrderDetail[];
  created_at: string;
  updated_at: string;
}

export const initialState: ManageState = {
  customers: [],
  mobilityOrders: [],
  dashboard: null,
  company: null,
  updateCompanyLoading: false,
};
