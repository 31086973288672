import { Routes } from '@angular/router';
import { authGuard } from './guards/auth/auth.guard';
import { manageUsersGuard } from './guards/manage-users/manage-users.guard';
import { NavigationRoutes } from './shared/navigation-routes.enum';

export const routes: Routes = [
  {
    path: NavigationRoutes.Catalog,
    loadChildren: () =>
      import('./modules/catalog/routes').then((m) => m.routes),
    canMatch: [authGuard],
  },
  {
    path: NavigationRoutes.Offers,
    loadChildren: () => import('./modules/offers/routes').then((m) => m.routes),
    canMatch: [authGuard],
  },
  {
    path: NavigationRoutes.Guudie,
    loadChildren: () => import('./modules/guudie/routes').then((m) => m.routes),
    canMatch: [authGuard],
  },
  {
    path: NavigationRoutes.Checkout,
    loadChildren: () =>
      import('./modules/checkout/routes').then((m) => m.routes),
    canMatch: [authGuard],
  },
  {
    path: NavigationRoutes.Checkout,
    loadChildren: () =>
      import('./modules/checkout/routes').then((m) => m.routes),
    canMatch: [authGuard],
  },
  {
    path: NavigationRoutes.Profile,
    loadChildren: () =>
      import('./modules/profile/routes').then((m) => m.routes),
    canMatch: [authGuard],
  },
  {
    path: NavigationRoutes.Manage,
    loadChildren: () => import('./modules/manage/routes').then((m) => m.routes),
    canMatch: [authGuard, manageUsersGuard],
  },
  {
    path: NavigationRoutes.Login,
    loadChildren: () => import('./modules/login/routes').then((m) => m.routes),
  },
  {
    path: NavigationRoutes.SetPassword,
    loadChildren: () =>
      import('./modules/set-password/routes').then((m) => m.routes),
  },

  {
    path: '**',
    redirectTo: NavigationRoutes.Catalog,
  },
];
