import { IProductPrice } from '../../shared/api.interfaces';

export interface Cart {
  object: string;
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  email: string;
  billing_address_id: null;
  shipping_address_id: null;
  region_id: string;
  customer_id: string;
  payment_id: null;
  type: string;
  completed_at: null;
  payment_authorized_at: null;
  idempotency_key: null;
  context: Context;
  metadata: null;
  sales_channel_id: string;
  customer: Customer;
  discounts: any[];
  gift_cards: any[];
  items: Item[];
  payment: null;
  region: Region;
  shipping_address: null;
  shipping_methods: any[];
  subtotal: number;
  discount_total: number;
  item_tax_total: number;
  shipping_total: number;
  shipping_tax_total: number;
  tax_total: number;
  raw_discount_total: number;
  gift_card_total: number;
  gift_card_tax_total: number;
  total: number;
}

export interface Context {
  ip: string;
  user_agent: string;
}

export interface Group {
  id: string;
  name: string;
}

export interface Order {
  status: string;
  created_at: string;
  fulfillment_status: string;
  payment_status: string;
  display_id: number;
  cart_id: string;
  cart: Cart;
  customer_id: string;
  customer: Customer;
  email: string;
  billing_address_id: string;
  billing_address: BillingAddress;
  shipping_address_id: string;
  shipping_address: BillingAddress;
  region_id: string;
  region: Region;
  currency_code: string;
  tax_rate: number | null;
  discounts: any[];
  draft_order_id: string;
  items: Item[];
  total: number;
  paid_total: number;
}

export interface BillingAddress {
  id: string;
  customer_id: string | null;
  company: string | null;
  first_name: string | null;
  last_name: string | null;
  address_1: string | null;
  address_2: string | null;
  city: string | null;
  country_code: string | null;
  province: string | null;
  postal_code: string | null;
  phone: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
}

export interface Company {
  name: string;
}

export interface Customer {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  email: string;
  first_name: string;
  last_name: string;
  billing_address_id: string | null;
  billing_address?: BillingAddress;
  shipping_addresses?: BillingAddress[];
  phone: null;
  has_account: boolean;
  metadata: CustomerMetadata;
  company_id: string;
  company?: Company;
  mobility_budget: number;
  groups: Group[];
  orders: Order[];
}

export interface CustomerMetadata {
  cartInitialized: boolean;
}

export interface Item {
  id: string;
  created_at: string;
  updated_at: string;
  cart_id: string;
  order_id: null;
  swap_id: null;
  claim_order_id: null;
  original_item_id: null;
  order_edit_id: null;
  title: string;
  description: string;
  thumbnail: string;
  is_return: boolean;
  is_giftcard: boolean;
  should_merge: boolean;
  allow_discounts: boolean;
  has_shipping: boolean;
  unit_price: number;
  variant_id: string;
  quantity: number;
  fulfilled_quantity: null;
  returned_quantity: null;
  shipped_quantity: null;
  metadata: ItemMetadata;
  adjustments: any[];
  tax_lines: TaxLine[];
  variant: Variant;
  subtotal: number;
  discount_total: number;
  total: number;
  original_total: number;
  original_tax_total: number;
  tax_total: number;
  raw_discount_total: number;
}

export interface ItemMetadata {}

export interface TaxLine {
  rate: number;
  name: string;
  code: string;
  item_id: string;
}

export interface Variant {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  title: string;
  product_id: string;
  sku: null;
  barcode: null;
  ean: null;
  upc: null;
  variant_rank: number;
  inventory_quantity: number;
  allow_backorder: boolean;
  manage_inventory: boolean;
  hs_code: null;
  origin_country: null;
  mid_code: null;
  material: null;
  weight: null;
  length: null;
  height: null;
  width: null;
  metadata: null;
  product: Product;
  prices?: IProductPrice[];
}

export interface Product {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  title: string;
  subtitle: null;
  description: string;
  handle: string;
  is_giftcard: boolean;
  status: string;
  thumbnail: string;
  weight: null;
  length: null;
  height: null;
  width: null;
  hs_code: null;
  origin_country: null;
  mid_code: null;
  material: null;
  collection_id: string;
  type_id: string;
  discountable: boolean;
  external_id: null;
  metadata: ItemMetadata;
  profiles: Profile[];
  profile: Profile;
  profile_id: string;
}

export interface Profile {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  name: string;
  type: string;
  metadata: null;
}

export interface Region {
  id: string;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  name: string;
  currency_code: string;
  tax_rate: number;
  tax_code: null;
  gift_cards_taxable: boolean;
  automatic_taxes: boolean;
  tax_provider_id: null;
  metadata: null;
  tax_rates: any[];
}

export interface CartState {
  cart: Cart | null;
  loading: boolean;
}

export const initialState: CartState = {
  cart: null,
  loading: false,
};
