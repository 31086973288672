<mat-toolbar class="w-full" [ngClass]="extendClass">
  <div class="container mx-auto navbar w-full h-full">
    <div class="flex justify-start ml-2">
      <!-- Left-aligned section -->

      <sf-img
        class="lg:w-32 w-28 cursor-pointer"
        type="svg"
        src="guudies-logo.svg"
        (click)="router.navigate([navigationRoutes.Catalog])"
      ></sf-img>

      <ng-component
        *ngTemplateOutlet="desktopRoutes; context: { position: 'start' }"
      ></ng-component>
    </div>
    <div class="flex justify-center">
      <!-- Center-aligned section -->
      @if (!isMobile) {
        <ng-component
          *ngTemplateOutlet="desktopRoutes; context: { position: 'center' }"
        ></ng-component>
      }
    </div>
    <div class="flex justify-end">
      <!-- Right-aligned section -->
      <ng-component
        *ngTemplateOutlet="desktopRoutes; context: { position: 'end' }"
      ></ng-component>

      <span class="mx-1 ml-3 flex items-center">
        <mat-divider
          class="h-2/3 w-px-1 bg-guud-green-palette-200"
          [vertical]="true"
        ></mat-divider>
      </span>

      @if (menuRoutes().length > 0) {
        <div class="flex">
          <button mat-icon-button [matMenuTriggerFor]="menu" class="!w-full">
            <div class="flex flex-row gap-4 items-center">
              <sf-img type="svg" src="login.svg"></sf-img>
            </div>
          </button>
          <mat-menu #menu="matMenu">
            @for (route of routes(); track route.name) {
              @if (route.position === "menu") {
                @if (route.show()) {
                  <button
                    mat-menu-item
                    [routerLink]="route.route"
                    [routerLinkActive]="'text-red-200'"
                    class="px-3 py-2"
                    (click)="route?.action?.()"
                  >
                    {{ route.name }}
                  </button>
                }
              }
            }
          </mat-menu>

          <ng-component
            *ngTemplateOutlet="
              desktopRoutes;
              context: { position: 'after-menu' }
            "
          ></ng-component>
        </div>
      }
    </div>
  </div>
</mat-toolbar>

<ng-template #desktopRoutes let-position="position">
  @for (route of routes(); track route.name) {
    <!--  -->
    @if (route.position === position) {
      <!--  -->
      @if (route.show()) {
        @if (route.icon) {
          <div>
            <button mat-icon-button [routerLink]="route.route">
              <sf-img
                [src]="route.icon.name"
                [type]="route.icon.type"
                [alt]="route.name"
                class="text-sm"
                [matBadgeHidden]="
                  route.badge?.() === undefined || route.badge?.() === 0
                "
                [matBadge]="route.badge?.()"
                matBadgeSize="small"
                matBadgeColor="accent"
              ></sf-img>
            </button>
          </div>
        } @else {
          <a
            mat-button
            [routerLink]="route.route"
            [routerLinkActive]="'text-red-200'"
            class="px-3 py-2"
            >{{ route.name }}</a
          >
        }
      }
    }
  }
</ng-template>
