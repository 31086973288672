{
  "navigation": {
    "home": "Home",
    "catalog": "Catalog",
    "offers": "Offers",
    "guudie": "Guudie",
    "cart": "Cart",
    "checkout": "Checkout",
    "profile": "Profile",
    "manage": "Company",
    "login": "Login",
    "logout": "Logout",
    "password-reset": "Password Reset"
  },
  "catalog": {
    "showAll": "Show all",
    "goToOffer": "go to offer",
    "addToCart": "Add to cart"
  },
  "login": {
    "signIn": "Sign in to your account",
    "resetPassword": "Request password reset",
    "successReset": "If your email is registered, you will shortly receive a reset email.",
    "email": "Email address",
    "login": "Login",
    "password": "Password",
    "submit": "Submit",
    "forgotPassword": "Forgot password?",
    "backToLogin": "Back to login",
    "error": {
      "login": "An error happened. Please check the email and password!",
      "serviceUnavailable": "Our platform is currently unavailable due to maintenance. Please try again later."
    }
  },
  "input": {
    "required": "This field is required",
    "invalidEmail": "Please enter a valid email address",
    "pattern": "Please enter a valid value",
    "hidePassword": "Hide password",
    "passwordMissmatch": "Passwords do not match",
    "min": "Bellow minimum quantity of {{min}}",
    "max": "Maximum quantity is {{max}}"
  },
  "insteadOf": "instead of",
  "toProduct": "To product",
  "addToCart": "Add to cart",
  "category": "Category",
  "discount": "Discount",
  "goToDiscountCode": "Go to discount code",
  "discountCodeCopied": "Discount code copied to clipboard",
  "quantity": "Quantity",
  "showCouponCode": "Show coupon code",
  "offers": {
    "soldOut": "Sold out",
    "clearFilters": "Clear all filters ({{count}})",
    "noProducts": "No products found",
    "productType": {
      "purchase-coupon": "Purchase coupon",
      "purchase-product": "Products",
      "coupon": "Coupon",
      "link": "Link"
    }
  },
  "guudieDetail": {
    "soldOut": "Sold out",
    "openLink": "Open link in new tab",
    "description": "Description",
    "similarProducts": "Similar products",
    "showMore": "Show more",
    "showLess": "Show less",
    "variant": "Variant",
    "redeemAt": "Redeem at:"
  },
  "checkout": {
    "noAddress": "No address provided. Please add an address in your profile.",
    "toProfile": "To profile",
    "continueShopping": "Continue shopping",
    "mobilityBudget": "Available mobility budget:",
    "mobilityBudgetAmount": "I want to use the following amount of my mobility budget for this order:",
    "mobilityBudgetRemoved": "The mobility budget has been removed due to a change in quantity. Please add the mobility budget again.",
    "subtract": "Subtract",
    "total": "Total costs (including VAT):",
    "toCheckout": "To checkout",
    "backToShop": "Back to shop",
    "cartEmpty": "Shopping cart is empty",
    "yourCart": "Your shopping cart",
    "product": "Product",
    "minusMobilityBudget": "minus mobility budget:",
    "shippingAddress": "Shipping address",
    "useBillingAddress": "Identical to billing address",
    "useDifferenntShippingAddress": "Specify a different Shipping address",
    "useExistingShippingAddress": "Use existing Shipping address",
    "newShippingAddress": "New shipping address",
    "failed": {
      "somethingWentWrong": "Something went wrong.",
      "tryAgain": "Please try again or contact support.",
      "backToCart": "Return to shopping cart"
    },
    "success": {
      "thankYou": "Thank you for your order!",
      "receiveEmail": "You will receive an email from us shortly!",
      "toProducts": "Back to the products"
    }
  },
  "setPassword": {
    "email": "Email address",
    "resetPassword": "Reset password",
    "confirmPassword": "Confirm password",
    "password": "Password",
    "success": "Password successfully reset.",
    "tokenInvalid": "The link seems to have expired. Request a new one at <a class='hover:underline text-blue-600' href=\"/login\">Forgot password</a> or contact support at <a class='hover:underline text-blue-600' href=\"mailto:support@guudies.ch\">support@guudies.ch</a>",
    "error": "An error occurred. Please contact support."
  },
  "manage": {
    "company": {
      "details": "Details",
      "name": "Name of the company",
      "save": "Save"
    },
    "dashboard": {
      "dashboard": "Dashboard",
      "employees": "Employees",
      "hrAdmin": "HR-Admin",
      "nrMobilityBudgetOrders": "Number of mobility budget orders",
      "totalValueMobilityBudgetOrders": "Total value of mobility budget orders"
    },
    "order": {
      "show": {
        "details": "Mobility budget order detail"
      },
      "orderMobilityBudget": "Order mobility budget",
      "allocateMobilityBudget": "Allocate mobility budget",
      "mobilityBudgetOrderSuccess": "Mobility budget order successfully placed.",
      "mobilityBudgetOrderFailure": "Mobility budget order failed.",
      "orderForNrUsers": "Order for number of users",
      "total": "Order Total"
    },
    "users": {
      "create": {
        "withCsvImport": "With CSV import:",
        "invite": "Invite user",
        "dragAndDrop": "drag and drop",
        "csvWrong1": "The CSV file must correspond to the",
        "csvWrong2": "template!",
        "csvWrong3": "",
        "downloadTemplate": "Download template",
        "invitedEmployees": "{{count}} employee(s) invited",
        "duplicateError": "Upload failed. One or more e-mail addresses are already registered."
      },
      "delete": {
        "deleteUser": "Delete user",
        "confirmDelete": "Should user {{ firstName }} {{ lastName }} ({{ email }}) really be deleted?",
        "confirmInfo": "This process cannot be reversed."
      },
      "edit": {
        "editUser": "Edit user"
      },
      "manageEmployees": "Manage employees",
      "backToShop": "Back to shop"
    }
  },
  "profile": {
    "orders": "Orders",
    "orderDetails": "Order",
    "orderFrom": "from",
    "personalData": "Personal data",
    "backToShop": "Back to shop",
    "mobilityBudget": "Mobility budget",
    "myMobilityBudget": "My mobility budget",
    "availableMobilityBudget": "Your currently available mobility budget:",
    "saveAddress": "Save address",
    "newPassword": "New password",
    "repeatNewPassword": "Repeat new password",
    "changePassword": "Change password",
    "order": {
      "number": "Order number",
      "date": "Order datw",
      "total": "Total of order",
      "productName": "Name",
      "variant": "Variant",
      "userMobilityBudget": "Mobility budget used",
      "deliveryAddress": "Delivery address",
      "billingAddress": "Billing address"
    }
  },
  "footer": {
    "dataPrivacy": "Privacy Policy",
    "generalTerms": "Terms and conditions",
    "imprint": "Imprint"
  },
  "info": {
    "street": "Street",
    "street2": "Address addition",
    "poBox": "PO Box",
    "city": "City",
    "zipCode": "Zip code",
    "country": "Country",
    "costCenter": "Cost center",
    "vatNumber": "VAT number",
    "firstName": "First name",
    "lastName": "Last name",
    "company": "Company",
    "billingAddress": "Billing address",
    "streetAndNumber": "Street and house number",
    "personResponsible": "Person responsible firstname and lastname",
    "personResponsibleEmail": "Person responsible e-mail",
    "nameChange": "Name changes must be requested from support.",
    "additionalAddressInfo": "Additional address information",
    "total": "Total",
    "email": "E-mail",
    "mobilityBudget": "Mobility budget",
    "amount": "Amount",
    "actions": "Actions",
    "phone": "Phone",
    "password": "Password"
  },
  "actions": {
    "edit": "Edit",
    "delete": "Delete",
    "add": "Add",
    "save": "Save",
    "cancel": "Cancel",
    "back": "Back",
    "confirm": "Confirm",
    "close": "Close",
    "order": "Order",
    "login": "Login"
  },
  "audit": {
    "createdBy": "Created by",
    "createdOn": "Created on",
    "updatedBy": "Updated by",
    "updatedOn": "Updated on"
  }
}
