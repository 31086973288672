import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideTransloco } from '@ngneat/transloco';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideAngularSvgIcon } from 'angular-svg-icon';
import { routes } from './app.routes';
import { userContextInterceptor } from './interceptors/user-context/user-context.interceptor';
import { CartEffects } from './state/cart/cart.effects';
import { CartReducer } from './state/cart/cart.reducer';
import { ManageEffects } from './state/manage/manage.effects';
import { ManageReducer } from './state/manage/manage.reducers';
import { UserEffects } from './state/user/user.effects';
import { UserReducer } from './state/user/user.reducer';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideMarkdown } from 'ngx-markdown';
import { maintenanceModeInterceptor } from './interceptors/maintenance-mode/maintenance-mode.interceptor';

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(
      withInterceptors([maintenanceModeInterceptor, userContextInterceptor]),
    ),
    provideRouter(routes),
    provideStore({
      user: UserReducer,
      cart: CartReducer,
      manage: ManageReducer,
    }),
    provideEffects(CartEffects, ManageEffects, UserEffects),
    provideAnimationsAsync(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'de'],
        defaultLang: 'de',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideAngularSvgIcon(),
    provideMarkdown(),
  ],
};
