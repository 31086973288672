import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { EMPTY, forkJoin, of } from 'rxjs';
import { map, exhaustMap, catchError, tap, switchMap } from 'rxjs/operators';
import { CartActions } from './cart.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { UserActions } from '../user/user.actions';
import { selectUser } from '../user/user.selectors';
import { Store } from '@ngrx/store';
import { SnackbarService } from '../../modules/snackbar/snackbar.service';
import { Cart, Customer } from './cart.state';

@Injectable()
export class CartEffects {
  private httpClient = inject(HttpClient);
  private actions$ = inject(Actions);
  private store = inject(Store);
  private snackbarService = inject(SnackbarService);

  private user$ = this.store.select(selectUser);

  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.loadCart),
      exhaustMap(() => this.getCart$()),
    ),
  );

  subtractMobilityBudget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.subractMobilityBudget),
      exhaustMap(({ amount }) =>
        this.httpClient
          .post(
            `${environment.baseApiUrl}/checkout/subtract`,
            { amount: amount * 100 },
            { withCredentials: true },
          )
          .pipe(
            map((cart) => CartActions.setCart({ cart })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  removeMobilityBudget$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.removeMobilityBudget),
      exhaustMap(() =>
        this.httpClient
          .delete(`${environment.baseApiUrl}/checkout/remove`, {
            withCredentials: true,
          })
          .pipe(switchMap(() => this.getCart$())),
      ),
    ),
  );

  updateItemQuantity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.updateItemQuantity),
      exhaustMap(({ cartId, itemId, quantity }) =>
        this.httpClient
          .post<{ cart: Cart }>(
            `${environment.baseApiUrl}/carts/${cartId}/line-items/${itemId}`,
            {
              quantity,
            },
            { withCredentials: true },
          )
          .pipe(
            tap((data) => {
              if (data.cart?.items?.length === 0) {
                this.store.dispatch(CartActions.removeMobilityBudget());
              }
            }),
            switchMap(() => this.getCart$()),
          ),
      ),
    ),
  );

  attemptCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.attemptCheckout),
      exhaustMap(({ mode, data }) =>
        this.httpClient
          .post<any>(
            `${environment.baseApiUrl}/checkout/attempt`,
            { mode, data },
            {
              withCredentials: true,
            },
          )
          .pipe(
            tap((checkout) =>
              checkout?.data?.[0]?.link
                ? window.open(checkout.data[0].link, '_self')
                : null,
            ),
            catchError(() => of(CartActions.setLoading({ loading: false }))),
          ),
      ),
    ),
  );

  addProductVariantToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.addProductToCart),
      exhaustMap(({ cartId, itemId, quantity }) =>
        this.httpClient
          .post(
            `${environment.baseApiUrl}/carts/${cartId}/line-items`,
            {
              variant_id: itemId,
              quantity,
            },
            { withCredentials: true },
          )
          .pipe(
            switchMap(() => {
              this.snackbarService.openSnackbar(
                'Produkt in den Warenkorb hinzugefügt.',
              );
              return this.getCart$();
            }),
            catchError((err) => {
              if (err?.error?.code === 'insufficient_inventory') {
                this.snackbarService.openSnackbar(
                  'Produkt hat nicht den erforderlichen Bestand.',
                );
              }
              return EMPTY;
            }),
          ),
      ),
    ),
  );

  createCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.createCart),
      exhaustMap(() =>
        this.httpClient
          .post<{
            cart: Record<string, string>;
          }>(`${environment.baseApiUrl}/carts`, {}, { withCredentials: true })
          .pipe(
            switchMap(({ cart }) => {
              return forkJoin([
                of(cart),
                this.httpClient
                  .post<Customer>(
                    `${environment.baseApiUrl}/customers/me`,
                    {
                      metadata: {
                        cartInitialized: !!(cart as Record<string, string>)?.[
                          'id'
                        ],
                      },
                    },
                    { withCredentials: true },
                  )
                  .pipe(
                    tap((user) =>
                      this.store.dispatch(UserActions.setUser({ user })),
                    ),
                  ),
              ]);
            }),
            map(([cart, user]) => CartActions.setCart({ cart })),
            catchError(() => EMPTY),
          ),
      ),
    ),
  );

  createCartIfNotExists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CartActions.createCartIfNotExists),
      exhaustMap(() =>
        this.user$.pipe(
          map((user) => {
            if (!user?.metadata?.cartInitialized) {
              return CartActions.createCart();
            }
            return CartActions.loadCart();
          }),
        ),
      ),
    ),
  );

  private getCart$() {
    return this.httpClient
      .get<any>(`${environment.baseApiUrl}/get-cart`, {
        withCredentials: true,
      })
      .pipe(
        map((cart) => CartActions.setCart({ cart })),
        catchError(() => {
          return EMPTY;
        }),
      );
  }
}
